import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Button, Typography, Space } from 'antd';
import { useDispatch } from 'react-redux';
import { login } from '../redux/authSlice';

const { Title } = Typography;

const Login = () => {

    const dispatch = useDispatch()

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const LoginData = {
        username,
        password
    }

    const handleLogin = () => {
        
        if (LoginData.username === 'admin' && LoginData.password === 'admin') {
            
            console.log(LoginData);
            dispatch(login())
            navigate('/');
            setError('')

        } else {
            setError('Username Or Password Not Currect')
        }

    };

    return (
        <div>
            <div style={{ maxWidth: '400px', margin: 'auto', padding: '50px 0' }}>
                <Title level={2} style={{ textAlign: 'center' }}>Admin Login</Title>
                <Form name="login" initialValues={{ remember: true }} onFinish={handleLogin}>
                    <Form.Item name="username" rules={[{ required: true, message: 'Please input your username!' }]}>
                        <Input placeholder="Username" onChange={(e) => setUsername(e.target.value)} />
                    </Form.Item>
                    <Form.Item name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
                        <Input.Password placeholder="Password" onChange={(e) => setPassword(e.target.value)} autoComplete='off' />
                    </Form.Item>
                    <Space>
                        <p style={{ color: 'red' }}>{error}</p>
                    </Space>
                    <Form.Item>
                        <Space>
                            <Button type="primary" htmlType="submit">Login</Button>
                        </Space>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}

export default Login
