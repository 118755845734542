import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// const firebaseConfig = {
//     apiKey: "AIzaSyDiujhfTFvYh4SOv1HhkdtoWmqxvK1VW68",
//     authDomain: "code-word-puzzle-game.firebaseapp.com",
//     projectId: "code-word-puzzle-game",
//     storageBucket: "code-word-puzzle-game.appspot.com",
//     messagingSenderId: "1054300774746",
//     appId: "1:1054300774746:web:890cbae4588b1bc5c39363",
//     measurementId: "G-S6WWKBLBPT"
// };

const firebaseConfig = {
    apiKey: "AIzaSyAGR1dA32AzQihWervQPV38kBJg8hI58Ds",
    authDomain: "rolthy-delivery.firebaseapp.com",
    databaseURL: "https://rolthy-delivery-default-rtdb.firebaseio.com",
    projectId: "rolthy-delivery",
    storageBucket: "rolthy-delivery.appspot.com",
    messagingSenderId: "1059854396431",
    appId: "1:1059854396431:web:189c1019b70ded92e1477a",
    measurementId: "G-3WVCC2EM7J"
  };

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

export {app, db}
