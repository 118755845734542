import React from 'react';
import { Select, DatePicker } from 'antd';

const { Option } = Select;
const { RangePicker } = DatePicker;

const DateFilter = ({ filter, customRange, onFilterChange, onCustomRangeChange }) => {
    return (
        <div style={{ marginBottom: '-33px' }}>
            <Select
                value={filter}
                onChange={onFilterChange}
                style={{ width: 160, marginRight: 16 }}
            >
                <Option value="all">All</Option>
                <Option value="today">Today</Option>
                <Option value="thisWeek">This Week</Option>
                <Option value="thisMonth">This Month</Option>
                <Option value="thisYear">This Year</Option>
                <Option value="custom">Custom Range</Option>
            </Select>
            {filter === 'custom' && (
                <RangePicker
                    value={customRange}
                    onChange={onCustomRangeChange}
                    style={{ marginRight: 16 }}
                />
            )}
        </div>
    );
};

export default DateFilter;
