import React from 'react';
import { Navigate } from 'react-router-dom';
import Home from '../pages/Home';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ component: Component }) => {

    const isAuthenticated = useSelector(state => state.auth.isAuthenticate);

    return isAuthenticated ? (
        <Home>
            <Component />
        </Home>
    ) : (
        <Navigate to="/login" />
    );

}

export default PrivateRoute
