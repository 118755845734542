import React, { useState } from 'react';
import EntityTable from '../components/EntityTable';
import DateFilter from '../filter/DateFilter';


const columns = [
    { title: 'ID', dataIndex: '_id', key: '_id' },
    { title: 'Customer First Name', dataIndex: 'customer.firstname', key: 'customerFirstname' },
    { title: 'Customer Last Name', dataIndex: 'customer.lastname', key: 'customerLastname' },
    { title: 'Customer Email', dataIndex: 'customer.email', key: 'customerEmail' },
    { title: 'Customer Phone', dataIndex: 'customer.mobile', key: 'customerMobile' },
    { title: 'Total Amount', dataIndex: 'amount', key: 'amount' },
    { title: 'Status', dataIndex: 'status', key: 'status' },
];

const Payments = () => {

    const [filter, setFilter] = useState('all');
    const [customRange, setCustomRange] = useState([null, null]);
    const [refreshKey, setRefreshKey] = useState(0);

    const handleFilterChange = (value) => {
        setFilter(value);
        if (value !== 'custom') {
            setCustomRange([null, null]);
        }
    };

    const handleCustomRangeChange = (dates) => {
        setCustomRange(dates);
    };

    const handleApplyFilters = () => {
        setRefreshKey(oldKey => oldKey + 1);
    };

    return (
        <div>
            <DateFilter
                filter={filter}
                customRange={customRange}
                onFilterChange={handleFilterChange}
                onCustomRangeChange={handleCustomRangeChange}
            />
            <EntityTable
                endpoint="https://service.habitoza.com/api/payments"
                columns={columns}
                filter={filter}
                customRange={customRange}
                refreshKey={refreshKey}
            />
        </div>
    );

}

export default Payments
