import React, { useEffect, useState } from 'react';
import { Typography, Row, Col, Card, Select, DatePicker } from 'antd';
import axios from 'axios';
import OrdersPaymentsChart from '../charts/OrdersPaymentsChart';

const { Title } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

const Dashboard = () => {
    const [loading, setLoading] = useState(false);
    const [totalUser, setTotalUser] = useState();
    const [totalOrders, setTotalOrders] = useState();
    const [totalPayments, setTotalPayments] = useState();
    const [totalSubscription, setTotalSubscription] = useState();
    const [filter, setFilter] = useState('all');
    const [customRange, setCustomRange] = useState([]);

    useEffect(() => {
        fetchData();
    }, [filter, customRange]);

    const fetchData = async () => {
        setLoading(true);
        try {
            const userResult = await axios.post('https://service.habitoza.com/api/customers');
            const ordersResult = await axios.post('https://service.habitoza.com/api/orders');
            const paymentsResult = await axios.post('https://service.habitoza.com/api/payments');
            const subscriptionResult = await axios.post('https://service.habitoza.com/api/subscriptions');

            let filteredUser = applyDateFilter(userResult.data.data);
            let filteredOrders = applyDateFilter(ordersResult.data.data);
            let filteredPayments = applyDateFilter(paymentsResult.data.data);
            let filteredSubscription = applyDateFilter(subscriptionResult.data.data);

            setTotalUser(filteredUser.length);
            setTotalOrders(filteredOrders.length);

            const total = filteredPayments.reduce((acc, item) => acc + item.amount, 0);
            const formattedAmount = total.toFixed(2);
            setTotalPayments(formattedAmount);

            setTotalSubscription(filteredSubscription.length);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const applyDateFilter = (data) => {
        if (filter === 'all') return data;

        const now = new Date();
        const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const startOfWeek = new Date(now.setDate(now.getDate() - now.getDay()));
        const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
        const startOfYear = new Date(now.getFullYear(), 0, 1);

        let startDate, endDate;

        switch (filter) {
            case 'Today':
                startDate = startOfDay;
                endDate = new Date(startOfDay);
                endDate.setDate(endDate.getDate() + 1);
                break;
            case 'This Week':
                startDate = startOfWeek;
                endDate = new Date(startOfWeek);
                endDate.setDate(endDate.getDate() + 7);
                break;
            case 'This Month':
                startDate = startOfMonth;
                endDate = new Date(startOfMonth);
                endDate.setMonth(endDate.getMonth() + 1);
                break;
            case 'This Year':
                startDate = startOfYear;
                endDate = new Date(startOfYear);
                endDate.setFullYear(endDate.getFullYear() + 1);
                break;
            case 'Custom Range':
                if (customRange.length !== 2) {
                    return data;
                }
                startDate = new Date(customRange[0]);
                endDate = new Date(customRange[1]);
                endDate.setDate(endDate.getDate() + 1);
                break;
            default:
                return data;
        }

        return data.filter(item => {
            const createAt = new Date(item.createAt);
            return createAt >= startDate && createAt < endDate;
        });
    };

    const handleFilterChange = (value) => {
        setFilter(value);
        if (value !== 'Custom Range') {
            setCustomRange([]);
        }
    };

    const handleCustomRangeChange = (dates, dateStrings) => {
        setCustomRange(dateStrings);
    };

    return (
        <div>
            <Title level={2}>Dashboard</Title>
            <Row gutter={16} style={{ marginBottom: 16, display: 'flex', justifyContent: 'end' }}>
                <Col>
                    <Select value={filter} onChange={handleFilterChange} style={{ width: 200 }}>
                        <Option value="all">All</Option>
                        <Option value="Today">Today</Option>
                        <Option value="This Week">This Week</Option>
                        <Option value="This Month">This Month</Option>
                        <Option value="This Year">This Year</Option>
                        <Option value="Custom Range">Custom Range</Option>
                    </Select>
                </Col>
                {filter === 'Custom Range' && (
                    <Col>
                        <RangePicker onChange={handleCustomRangeChange} />
                    </Col>
                )}
            </Row>
            <Row gutter={16}>
                <Col span={6}>
                    <Card title={filter === 'all' ? 'Total Customers' : filter + ' Customers'} bordered={false} loading={loading}>{totalUser}</Card>
                </Col>
                <Col span={6}>
                    <Card title={filter === 'all' ? 'Total Subscription' : filter + ' Subscription'} bordered={false} loading={loading}>{totalSubscription}</Card>
                </Col>
                <Col span={6}>
                    <Card title={filter === 'all' ? 'Total Orders' : filter + ' Orders'} bordered={false} loading={loading}>{totalOrders}</Card>
                </Col>
                <Col span={6}>
                    <Card title={filter === 'all' ? 'Total Payments' : filter + ' Payments'} bordered={false} loading={loading}>{totalPayments}</Card>
                </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: 24 }}>
                <Col span={24}>
                    <OrdersPaymentsChart />
                </Col>
            </Row>
        </div>
    );
};

export default Dashboard;
