import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './pages/Login';
import Customers from './pages/Customers';
import Drivers from './pages/Drivers';
import Marketplaces from './pages/Marketplaces';
import Products from './pages/Products';
import Orders from './pages/Orders';
import Payments from './pages/Payments';
import Subscriptions from './pages/Subscriptions';
import Advertisements from './pages/Advertisements';
import PrivateRoute from './components/PrivateRoute';
import 'antd/dist/reset.css';
import Dashboard from './components/Dashboard';
import Driverlocations from './components/Driverlocations';


function App() {
  
  return (
    <div className="App"> 
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<PrivateRoute component={Dashboard} />} />
          <Route path="/customers" element={<PrivateRoute component={Customers} />} />
          <Route path="/drivers" element={<PrivateRoute component={Drivers} />} />
          <Route path="/marketplaces" element={<PrivateRoute component={Marketplaces} />} />
          <Route path="/products" element={<PrivateRoute component={Products} />} />
          <Route path="/orders" element={<PrivateRoute component={Orders} />} />
          <Route path="/payments" element={<PrivateRoute component={Payments} />} />
          <Route path="/subscriptions" element={<PrivateRoute component={Subscriptions} />} />
          <Route path="/advertisements" element={<PrivateRoute component={Advertisements} />} />
          <Route path="/DriverLocations" element={<PrivateRoute component={Driverlocations} />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
