import React, { useEffect, useState } from 'react';
import { Card, Select } from 'antd';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import axios from 'axios';
import moment from 'moment';

const { Option } = Select;

const OrdersPaymentsChart = () => {
    const [selectedYear, setSelectedYear] = useState(moment().year());
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleYearChange = (year) => {
        setSelectedYear(year);
    };

    const fetchDataByYear = async (year) => {
        setLoading(true);
        try {
            const startDate = moment().year(year).startOf('year').format('YYYY-MM-DD');
            const endDate = moment().year(year).endOf('year').format('YYYY-MM-DD');

            const ordersResult = await axios.post('https://service.habitoza.com/api/orders', { startDate, endDate });
            const paymentsResult = await axios.post('https://service.habitoza.com/api/payments', { startDate, endDate });

            const orders = [];
            for (let month = 1; month <= 12; month++) {
                const monthName = moment().month(month - 1).format('MMM');

                const monthlyOrderData = ordersResult.data.data.filter(order =>
                    moment(order.date).month() === month - 1 && moment(order.date).year() === year
                );
                const monthlyPaymentsData = paymentsResult.data.data.filter(payments =>
                    moment(payments.date).month() === month - 1 && moment(payments.date).year() === year
                );

                const totalOrders = monthlyOrderData.length;
                const totalPayments = monthlyPaymentsData.reduce((acc, item) => acc + item.amount, 0);
                const formattedAmount = totalPayments.toFixed(2);


                orders.push({
                    name: monthName,
                    orders: totalOrders,
                    payments: formattedAmount
                });
            }

            setData(orders);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchDataByYear(selectedYear);
    }, [selectedYear]);

    const years = Array.from({ length: 5 }, (_, i) => moment().year() - i);

    return (
        <Card
            title="Orders & Payments"
            extra={
                <Select
                    value={selectedYear}
                    onChange={handleYearChange}
                    style={{ width: 120, marginRight: 16 }}
                >
                    {years.map(year => (
                        <Option key={year} value={year}>
                            {year}
                        </Option>
                    ))}
                </Select>
            }
            loading={loading}
        >
            <ResponsiveContainer width="100%" height={400}>
                <LineChart data={data}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="orders" stroke="#8884d8" activeDot={{ r: 8 }} />
                    <Line type="monotone" dataKey="payments" stroke="#82ca9d" />
                </LineChart>
            </ResponsiveContainer>
        </Card>
    );
}

export default OrdersPaymentsChart;
