import React from 'react';
import EntityTable from '../components/EntityTable';

const columns = [
    {
        title: 'Picture', dataIndex: 'pic', key: 'pic', render: (text) => (
            <img
                src={`https://service.habitoza.com/api/${text}`}
                alt="Profile"
                style={{ width: '80px', height: 'auto', maxHeight: '80px' }}
                onError={(e) => { e.target.onerror = null; e.target.src = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5tS0-C4U5qmVne478u0ctepoC40lYC_TdLg&s'; }}
            />
        )
    },
    { title: 'ID', dataIndex: '_id', key: '_id' },
    { title: 'First Name', dataIndex: 'firstname', key: 'firstname' },
    { title: 'Last Name', dataIndex: 'lastname', key: 'lastname' },
    { title: 'Email', dataIndex: 'email', key: 'email' },
    { title: 'Phone', dataIndex: 'mobile', key: 'mobile' },
];

const Customers = () => {

    return <EntityTable endpoint="https://service.habitoza.com/api/customers" columns={columns} filter={'all'} />;

}

export default Customers
