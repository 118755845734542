import React, { useEffect, useState } from 'react';
import { Space, Table, Input } from 'antd';
import axios from 'axios';
import transformData from '../utiles/transformData';

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}`;
};

const { Search } = Input;

const EntityTable = ({ endpoint, columns, filter, customRange, refreshKey }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [filteredData, setFilteredData] = useState([]);

    const fetchData = async () => {
        setLoading(true);
        try {
            const result = await axios.post(endpoint);
            let fetchedData = result.data.data;

            fetchedData = applyDateFilter(fetchedData);

            const transformedData = transformData(fetchedData, columns);
            setData(transformedData);
            setFilteredData(transformedData);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const applyDateFilter = (data) => {
        if (filter === 'all') return data;

        let startDate, endDate;
        const today = new Date();
        switch (filter) {
            case 'today':
                startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
                endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59);
                break;
            case 'thisWeek':
                startDate = new Date(today.setDate(today.getDate() - today.getDay()));
                endDate = new Date(today.setDate(startDate.getDate() + 6));
                break;
            case 'thisMonth':
                startDate = new Date(today.getFullYear(), today.getMonth(), 1);
                endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
                break;
            case 'thisYear':
                startDate = new Date(today.getFullYear(), 0, 1);
                endDate = new Date(today.getFullYear(), 11, 31);
                break;
            case 'custom':
                if (customRange.length !== 2) return data;
                startDate = new Date(customRange[0]);
                endDate = new Date(customRange[1]);
                break;
            default:
                break;
        }

        return data.filter(item => {
            const createAt = new Date(item.createAt);
            return createAt >= startDate && createAt <= endDate;
        });
    };

    useEffect(() => {
        fetchData();
    }, [filter, customRange, refreshKey]);

    const formattedColumns = columns.map(col => {
        if (['startOn', 'endAt', 'createAt'].includes(col.dataIndex)) {
            return {
                ...col,
                render: (text) => formatDate(text),
            };
        }
        return col;
    });

    const handleSearch = (value) => {
        setSearchText(value);
        const filtered = data.filter(item =>
            Object.values(item).some(val =>
                String(val).toLowerCase().includes(value.toLowerCase())
            )
        );
        setFilteredData(filtered);
    };

    return (
        <div>
            <Space style={{ marginBottom: 16, display: 'flex', justifyContent: 'flex-end' }}>
                <Search
                    placeholder="Search by Name, Email, Mobile"
                    value={searchText}
                    onChange={(e) => handleSearch(e.target.value)}
                    style={{ width: 300 }}
                />
            </Space>
            <Table
                columns={formattedColumns}
                dataSource={filteredData}
                loading={loading}
                rowKey={(record) => record._id}
                scroll={{ x: 'max-content' }}
            />
        </div>
    );
}

export default EntityTable;
