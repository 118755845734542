import React from 'react';
import { Layout, Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import {
    UserOutlined,
    DashboardOutlined,
    ShoppingCartOutlined,
    CreditCardOutlined,
    ShopOutlined,
    TagsOutlined,
    PicCenterOutlined,
    VideoCameraOutlined,
    TeamOutlined,
} from '@ant-design/icons';

const { Sider } = Layout;

const items = [
    {
        key: '/',
        icon: <DashboardOutlined />,
        label: <Link to="/">Dashboard</Link>,
    },
    {
        key: '/customers',
        icon: <UserOutlined />,
        label: <Link to="/customers">Customers</Link>,
    },
    {
        key: '/drivers',
        icon: <TeamOutlined />,
        label: <Link to="/drivers">Drivers</Link>,
    },
    {
        key: '/marketplaces',
        icon: <ShopOutlined />,
        label: <Link to="/marketplaces">Marketplaces</Link>,
    },
    {
        key: '/products',
        icon: <TagsOutlined />,
        label: <Link to="/products">Products</Link>,
    },
    {
        key: '/orders',
        icon: <ShoppingCartOutlined />,
        label: <Link to="/orders">Orders</Link>,
    },
    {
        key: '/payments',
        icon: <CreditCardOutlined />,
        label: <Link to="/payments">Payments</Link>,
    },
    {
        key: '/subscriptions',
        icon: <PicCenterOutlined />,
        label: <Link to="/subscriptions">Subscriptions</Link>,
    },
    {
        key: '/advertisements',
        icon: <VideoCameraOutlined />,
        label: <Link to="/advertisements">Advertisements</Link>,
    },
    {
        key: '/DriverLocations',
        icon: <TeamOutlined />,
        label: <Link to="/DriverLocations">Driver Locations</Link>,
    },
];

const Sidebar = () => {
    const location = useLocation();

    return (
        <div>
            <Sider collapsible style={{ minHeight: '100%' }}>
                <div className="logo"><h1 style={{color:'#fff', padding:'20px 0 0 40px'}}>LOGO</h1></div>
                <Menu
                    theme="dark"
                    mode="inline"
                    selectedKeys={[location.pathname]}
                    items={items}
                />
            </Sider>
        </div>
    );
};

export default Sidebar;
