import React from 'react';
import EntityTable from '../components/EntityTable';

const columns = [
    { title: 'Ad ID', dataIndex: 'adId', key: 'adId' },
    { title: 'Title', dataIndex: 'title', key: 'title' },
    { title: 'Platform', dataIndex: 'platform', key: 'platform' },
];

const Advertisements = () => {

    return <EntityTable endpoint="https://service.habitoza.com/api/advertisements" columns={columns} filter={'all'}  />;

}

export default Advertisements
