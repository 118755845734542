
import React, { useEffect, useRef, useState } from 'react';
import { MapContainer, TileLayer, Marker, Polyline, Tooltip, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = L.icon({
    iconUrl: markerIcon,
    iconRetinaUrl: markerIcon2x,
    shadowUrl: markerShadow,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowSize: [41, 41]
});

let RedIcon = L.icon({
    iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png',
    iconRetinaUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
    shadowUrl: markerShadow,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowSize: [41, 41]
});

L.Marker.prototype.options.icon = DefaultIcon;


const LocationMaps = ({ filteredData, setShowFilter }) => {

    const [selectedDriver, setSelectedDriver] = useState();
    const [mapCenter, setMapCenter] = useState({ point1: 30.43093228641138, point2: -84.28137723277186 });


    const mapRef = useRef();

    const MapUpdater = () => {
        const map = useMap();
        useEffect(() => {
            if (map) {
                map.setView([mapCenter.point1, mapCenter.point2], map.getZoom());
            }
        }, [mapCenter, map]);

        return null;
    };


    const handleMarkerClick = (driverId) => {
        setSelectedDriver(driverId);
    };

    useEffect(() => {
        setShowFilter(selectedDriver);
    }, [selectedDriver])


    useEffect(() => {
        console.log('filteredData:', filteredData); 
        if (Array.isArray(filteredData) && filteredData.length > 0) {
            const latestLocation = filteredData[0].LatestLocation;

            if (latestLocation && Array.isArray(latestLocation.location) && latestLocation.location.length === 2) {
                setMapCenter({
                    point1: latestLocation.location[0],
                    point2: latestLocation.location[1]
                });
            } else {
                setMapCenter({
                    point1: 30.43093228641138,
                    point2: -84.28137723277186
                });
            }
        } else {
            setMapCenter({
                point1: 30.43093228641138,
                point2: -84.28137723277186
            });
        }
    }, [filteredData]);





    return (
        <div>
            <MapContainer center={[mapCenter.point1, mapCenter.point2]} zoom={5} style={{ height: "600px", width: "100%" }} ref={mapRef}>
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                <MapUpdater />
                {filteredData ? filteredData.map((user, index) => (
                    <div key={index}>
                        <Marker

                            position={[user.LatestLocation ? user.LatestLocation.location[0] : '', user.LatestLocation ? user.LatestLocation.location[1] : '']}
                            title={`Driver ID: ${user.DriverID}`}
                            icon={user.DriverID === selectedDriver ? RedIcon : DefaultIcon}
                            eventHandlers={{
                                click: () => handleMarkerClick(user.DriverID),
                            }} >
                            <Tooltip permanent>
                                <span>
                                    {user.Name}<br />
                                    {user.Email}<br />
                                    {user.mobile}
                                </span>
                            </Tooltip>
                        </Marker>
                        {<Polyline
                            positions={user.DriverID === selectedDriver ? user.Locations.map(loc => [loc.location[0], loc.location[1]]) : ''}
                            color="red"
                        />}
                    </div>
                )) : 'Data Not Available'}
            </MapContainer>
        </div>
    );
};

export default LocationMaps;

