import React from 'react';
import EntityTable from '../components/EntityTable';

const columns = [
    { title: 'ID', dataIndex: '_id', key: '_id' },
    { title: 'Customer First Name', dataIndex: 'customer.firstname', key: 'customerFirstname' },
    { title: 'Customer Last Name', dataIndex: 'customer.lastname', key: 'customerLastname' },
    { title: 'Customer Email', dataIndex: 'customer.email', key: 'customerEmail' },
    { title: 'Customer Phone', dataIndex: 'customer.mobile', key: 'customerMobile' },
    { title: 'Delivery', dataIndex: 'delivery', key: 'delivery' },
    { title: 'Start On', dataIndex: 'startOn', key: 'startOn' },
    { title: 'End At', dataIndex: 'endAt', key: 'endAt' },
];

const Subscriptions = () => {

    return <EntityTable endpoint="https://service.habitoza.com/api/subscriptions" columns={columns} filter={'all'}  />;

}

export default Subscriptions
