import { getNestedValue } from './utils';

const transformData = (data, columns) => {
    return data.map(item => {
        const transformedItem = {};
        columns.forEach(column => {
            transformedItem[column.dataIndex] = getNestedValue(item, column.dataIndex);
        });
        return transformedItem;
    });
};

export default transformData;
