import React, { useState } from 'react';
import EntityTable from '../components/EntityTable';

const columns = [
    {
        title: 'Picture', dataIndex: 'pic', key: 'pic', render: (text) => (
            <img
                src={`https://service.habitoza.com/api/${text}`}
                alt="Profile"
                style={{ width: '80px', height: 'auto', maxHeight: '80px' }}
                onError={(e) => { e.target.onerror = null; e.target.src = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5tS0-C4U5qmVne478u0ctepoC40lYC_TdLg&s'; }}
            />
        )
    },
    { title: 'ID', dataIndex: '_id', key: '_id' },
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'Email', dataIndex: 'email', key: 'email' },
    { title: 'Phone', dataIndex: 'mobile', key: 'mobile' },
    { title: 'Delivery Slot', dataIndex: 'delivery_slot', key: 'delivery_slot' },
    { title: 'Slot Time', dataIndex: 'slot_time', key: 'slot_time' },
    { title: 'Opening', dataIndex: 'opening', key: 'opening' },
    { title: 'Closing', dataIndex: 'closing', key: 'closing' },
];

const Marketplaces = () => {

    return <EntityTable endpoint="https://service.habitoza.com/api/marketplaces" columns={columns} filter={'all'} />;

}

export default Marketplaces
