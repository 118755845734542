import React from 'react';
import { Layout, Dropdown, Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../redux/authSlice';

const { Header } = Layout;

const AppHeader = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleLogout = () => {
        dispatch(logout())
    };

    const items = [
        {
            key: '0',
            label: <Link onClick={() => navigate('/profile')}>Profile</Link>,
        },
        {
            key: '1',
            label: <Link onClick={() => navigate('/reset-password')}>Reset Password</Link>,
        },
        {
            type: 'divider',
        },
        {
            key: '3',
            label: <Link onClick={handleLogout}>Logout</Link>,
        },
    ];

    return (
        <div>
            <Header className="site-layout-background" style={{ padding: 0, textAlign: 'right' }}>
                <Dropdown menu={{ items }} trigger={['click']}>
                    <Link  className="ant-dropdown-link" onClick={e => e.preventDefault()} style={{ marginRight: 16 }}>
                        <Avatar icon={<UserOutlined />} />
                    </Link>
                </Dropdown>
            </Header>
        </div>
    )
}

export default AppHeader;
