import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const fetchUserLocations = createAsyncThunk(
    'driverlocation/fetchUserLocations',
    async (driverId) => {
        console.log('Driver ID Function', driverId);
        const data = {
            name: 'rahil',
            age: 22,
        };
        return data;
    }
);

const locationSlice = createSlice({
    name: 'driverlocation',
    initialState: {
        allLocationData: [],
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchUserLocations.fulfilled, (state, action) => {
            console.log(action.payload);
            state.allLocationData = action.payload;
        });
    },
});

export default locationSlice.reducer;
