import React, { useState } from 'react';
import EntityTable from '../components/EntityTable';
import DateFilter from '../filter/DateFilter';

const columns = [
    { title: 'ID', dataIndex: '_id', key: '_id' },
    { title: 'Customer First Name', dataIndex: 'customer.firstname', key: 'customerFirstname' },
    { title: 'Customer Last Name', dataIndex: 'customer.lastname', key: 'customerLastname' },
    { title: 'Customer Email', dataIndex: 'customer.email', key: 'customerEmail' },
    { title: 'Customer Phone', dataIndex: 'customer.mobile', key: 'customerMobile' },
    { title: 'Item Name', dataIndex: 'item.name', key: 'itemName' },
    { title: 'Item Category', dataIndex: 'item.category', key: 'itemCategory' },
    { title: 'Item Subcategory', dataIndex: 'item.subcategory', key: 'itemSubcategory' },
    { title: 'Item Company', dataIndex: 'item.company', key: 'itemCompany' },
    { title: 'Item Price', dataIndex: 'item.price', key: 'itemPrice' },
    { title: 'Quantity', dataIndex: 'quantity', key: 'quantity' },
    { title: 'Total Amount', dataIndex: 'price', key: 'price' },
    { title: 'Status', dataIndex: 'status', key: 'status' },
    { title: 'Order Date', dataIndex: 'createAt', key: 'createAt' },
];

const Orders = () => {
    const [filter, setFilter] = useState('all');
    const [customRange, setCustomRange] = useState([null, null]);
    const [refreshKey, setRefreshKey] = useState(0);

    const handleFilterChange = (value) => {
        setFilter(value);
        if (value !== 'custom') {
            setCustomRange([null, null]);
        }
    };

    const handleCustomRangeChange = (dates) => {
        setCustomRange(dates);
    };

    const handleApplyFilters = () => {
        setRefreshKey(oldKey => oldKey + 1);
    };

    return (
        <div>
            <DateFilter
                filter={filter}
                customRange={customRange}
                onFilterChange={handleFilterChange}
                onCustomRangeChange={handleCustomRangeChange}
            />
            <EntityTable
                endpoint="https://service.habitoza.com/api/orders"
                columns={columns}
                filter={filter}
                customRange={customRange}
                refreshKey={refreshKey}
            />
        </div>
    );
}

export default Orders;
