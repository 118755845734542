import React, { useEffect, useState, useRef } from 'react';
import { collection, getDocs, query, orderBy, limit } from 'firebase/firestore';
import { db } from '../Firebase/firebase';
import axios from 'axios';
import LocationMaps from './LocationMaps';
import { Select, DatePicker, TimePicker, Button, Space } from 'antd';
import 'antd/dist/reset.css';

const { Option } = Select;

const DriverLocations = () => {
    const [drivers, setDrivers] = useState([]);
    const [allLocationData, setAllLocationData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [filter, setFilter] = useState('thisMonth');
    const [customRange, setCustomRange] = useState({ start: null, end: null });
    const [showFilter, setShowFilter] = useState();
    const fetchedDriversRef = useRef(new Set());

    const [driverNameFilter, setDriverNameFilter] = useState([]);
    const [selectedDrivers, setSelectedDrivers] = useState([]);



    const formatTimestamp = (timestamp) => {
        const date = timestamp.toDate();
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${day}/${month}/${year} ${hours}:${minutes}`;
    };

    const fetchDriverIDs = async () => {
        try {
            const result = await axios.post('https://service.habitoza.com/api/drivers');
            setDrivers(result.data.data);

            setDriverNameFilter(result.data.data.map(driver => ({
                value: driver._id,
                label: `${driver.firstname} ${driver.lastname} (${driver.mobile})`
            })));

        } catch (error) {
            console.log(error);
        }
    };

    const fetchUserLocations = async (driverId, firstName, lastName, email, mobile) => {
        if (fetchedDriversRef.current.has(driverId)) {
            return;
        }
        fetchedDriversRef.current.add(driverId);

        try {
            const usersColRef = collection(db, `/rolthy-db/users/${driverId}`);
            const usersSnapshot = await getDocs(usersColRef);
            const usersList = usersSnapshot.docs.map(doc => {
                const data = doc.data();
                return {
                    ...data,
                    formattedCreateAt: formatTimestamp(data.createAt)
                };
            });

            const q = query(usersColRef, orderBy('createAt', 'desc'), limit(1));
            const usersSnapshotLatest = await getDocs(q);

            let latestLocation = null;
            let latestTimestamp = '';
            if (!usersSnapshotLatest.empty) {
                const latestDoc = usersSnapshotLatest.docs[0].data();
                latestLocation = latestDoc.location;
                latestTimestamp = formatTimestamp(latestDoc.createAt);
            }

            setAllLocationData(prevData => {
                const existingIndex = prevData.findIndex(item => item.DriverID === driverId);
                if (existingIndex !== -1) {
                    const updatedData = [...prevData];
                    updatedData[existingIndex] = {
                        DriverID: driverId,
                        Locations: usersList,
                        LatestLocation: latestLocation ? { location: latestLocation, timestamp: latestTimestamp } : null
                    };
                    return updatedData;
                } else {
                    return [
                        ...prevData,
                        {
                            DriverID: driverId,
                            Name: firstName + ' ' + lastName,
                            Email: email,
                            mobile: mobile,
                            Locations: usersList,
                            LatestLocation: latestLocation ? { location: latestLocation, timestamp: latestTimestamp } : null
                        }
                    ];
                }
            });
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    useEffect(() => {
        fetchDriverIDs();
    }, []);

    useEffect(() => {
        if (drivers.length > 0) {
            drivers.forEach(driver => {
                fetchUserLocations(driver._id, driver.firstname, driver.lastname, driver.email, driver.mobile);
            });
        }
    }, [drivers]);

    useEffect(() => {
        if (allLocationData.length > 0) {
            applyFilter();
        }
    }, [allLocationData, filter, customRange, selectedDrivers]);

    const applyFilter = () => {
        const now = new Date();
        const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const startOfWeek = new Date(now.setDate(now.getDate() - now.getDay()));
        const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
        const startOfYear = new Date(now.getFullYear(), 0, 1);

        let filtered = [...allLocationData];

        switch (filter) {
            case 'today':
                filtered = allLocationData.map(data => ({
                    ...data,
                    Locations: data.Locations.filter(loc => {
                        const locDate = new Date(loc.formattedCreateAt.split(' ')[0].split('/').reverse().join('-'));
                        return locDate >= startOfDay;
                    })
                }));
                break;
            case 'thisWeek':
                filtered = allLocationData.map(data => ({
                    ...data,
                    Locations: data.Locations.filter(loc => {
                        const locDate = new Date(loc.formattedCreateAt.split(' ')[0].split('/').reverse().join('-'));
                        return locDate >= startOfWeek;
                    })
                }));
                break;
            case 'thisMonth':
                filtered = allLocationData.map(data => ({
                    ...data,
                    Locations: data.Locations.filter(loc => {
                        const locDate = new Date(loc.formattedCreateAt.split(' ')[0].split('/').reverse().join('-'));
                        return locDate >= startOfMonth;
                    })
                }));
                break;
            case 'thisYear':
                filtered = allLocationData.map(data => ({
                    ...data,
                    Locations: data.Locations.filter(loc => {
                        const locDate = new Date(loc.formattedCreateAt.split(' ')[0].split('/').reverse().join('-'));
                        return locDate >= startOfYear;
                    })
                }));
                break;
            case 'customRange':
                if (customRange.start && customRange.end) {
                    const startDate = new Date(customRange.start);
                    const endDate = new Date(customRange.end);
                    filtered = allLocationData.map(data => ({
                        ...data,
                        Locations: data.Locations.filter(loc => {
                            const [datePart, timePart] = loc.formattedCreateAt.split(' ');
                            const [day, month, year] = datePart.split('/');
                            const [hours, minutes] = timePart.split(':');
                            const locDate = new Date(`${year}-${month}-${day}T${hours}:${minutes}:00`);
                            return locDate >= startDate && locDate <= endDate;
                        })
                    }));
                }
                break;
            default:
                break;
        }

        // Filter by selected drivers
        if (selectedDrivers.length > 0) {
            filtered = filtered.filter(data => selectedDrivers.includes(data.DriverID));
        }

        setFilteredData(filtered);
    };


    const handleCustomRangeChange = (dates, dateStrings) => {
        if (dates) {
            setCustomRange({ start: dates[0].toISOString(), end: dates[1].toISOString() });
        } else {
            setCustomRange({ start: null, end: null });
        }
    };

    const handleDriverSelect = (selectedDriverIds) => {
        setSelectedDrivers(selectedDriverIds);
    };

    return (
        <div>
            <div>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                    <h1>Driver Location</h1>

                    <Select
                        mode="multiple"
                        style={{ width: '400px', marginBottom: '20px' }}
                        placeholder="Select drivers"
                        onChange={handleDriverSelect}
                        showSearch
                        options={driverNameFilter}
                        value={selectedDrivers}
                        filterOption={(input, option) =>
                            option.label.toLowerCase().includes(input.toLowerCase())
                        }
                    />


                    {showFilter &&

                        <Space style={{ display: 'flex', justifyContent: 'end', marginBottom: '20px' }} direction="horizantal" size="large">
                            <Select
                                value={filter}
                                style={{ width: 200 }}
                                onChange={(value) => setFilter(value)}
                            >
                                <Option value="">None</Option>
                                <Option value="today">Today</Option>
                                <Option value="thisWeek">This Week</Option>
                                <Option value="thisMonth">This Month</Option>
                                <Option value="thisYear">This Year</Option>
                                <Option value="customRange">Custom Range</Option>
                            </Select>

                            {filter === 'customRange' && (
                                <DatePicker.RangePicker
                                    showTime
                                    format="YYYY-MM-DD HH:mm:ss"
                                    onChange={handleCustomRangeChange}
                                />
                            )}

                        </Space>
                    }
                </div>

                <LocationMaps filteredData={filteredData} setShowFilter={setShowFilter} />
            </div>
        </div>
    );
};

export default DriverLocations;
